import { useCallback, useState } from 'react';
import { AsyncStatus } from 'common';
import { useSnackbar } from 'components';
import TaskPreferencesService from 'tasks/services/task-preferences-service';
import { TaskPreference } from '../../model';

const usePreferencesUpdate = () => {
  const [status, setStatus] = useState<AsyncStatus>('idle');
  const { enqueueSnackbar } = useSnackbar();

  const patchPreferences = useCallback(
    (userId: number, preference: TaskPreference) => {
      setStatus('fetching');
      const [response] = TaskPreferencesService.patchPreferences(userId, preference);
      return response
        .then(res => {
          setStatus('resolved');
          return res;
        })
        .catch(e => {
          enqueueSnackbar(e.Message || 'Error occurred during applying changes to Preference.', 'error');
          setStatus('rejected');
        });
    },
    [enqueueSnackbar],
  );

  return {
    patchPreferences,
    isLoading: status === 'fetching',
  };
};

export default usePreferencesUpdate;
