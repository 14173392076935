import { Box, styled, Typography } from 'components';

export const StyledBox = styled(Box)(() => ({
  position: 'relative',
  padding: '0.7rem 1rem 0.5rem 1rem',
  marginBottom: '0.5rem',
  boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.2)',
  '& .MuiTypography-root': {
    display: 'block',
  },
}));

export const StyledTaskTitle = styled(Typography)<{ component?: string }>(() => ({
  display: 'block',
  marginBottom: '0.5rem',
  cursor: 'pointer',
}));

export const StyledApplicationReference = styled(Typography)<{ component?: string }>(({ theme }) => ({
  display: 'inline-block !important',
  width: 'auto',
  fontSize: '0.7rem',
  backgroundColor: `${theme.palette.text.secondary}`,
  color: 'white',
  padding: '2px 7px 1px 7px',
  borderRadius: '10px',
  marginBottom: '0.4rem',
}));
