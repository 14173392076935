import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Tooltip, Typography } from 'components';
import { TaskItem } from 'tasks/model';
import { taskTitleLength } from 'tasks/const';
import { StyledBox, StyledApplicationReference, StyledTaskTitle } from './TaskCardSlim.styles';

type TaskCardSlimProps = {
  handleClose: () => void;
  handleOpenTaskModal: (task: TaskItem) => void;
  task: TaskItem;
};

const TaskCardSlim: React.FC<TaskCardSlimProps> = ({ handleClose, handleOpenTaskModal, task }) => {
  return (
    <StyledBox>
      <Tooltip title={task.title.length > taskTitleLength ? task.title : null}>
        <StyledTaskTitle color="primary" onClick={() => handleOpenTaskModal(task)} variant="h6">
          {task.titleToDisplay}
        </StyledTaskTitle>
      </Tooltip>
      <StyledApplicationReference>
        {task.application.title} - {task.taskType.type}
      </StyledApplicationReference>
      <Typography variant="subtitle2">
        <Link component={RouterLink} target="_blank" onClick={handleClose} to={task.viewUrl}>
          Link
        </Link>
      </Typography>
    </StyledBox>
  );
};

export default TaskCardSlim;
