import { Modify } from 'common';
import { Attachment } from 'submissions/components/Attachments/model';
import { Cover } from 'submissions/components/CoversTab/model';
import { SubmissionObjects } from 'submissions/components/ObjectsTab/model';

export type InsurableObjectType = 'crew' | 'fleet' | 'yacht' | 'vessel';

export type InsurableObjectTypeCode = 'CW' | 'FT' | 'YT' | 'VS';

type CommonObjectDetails = {
  quoteObjectId: number;
  quoteCoverId: number;
  type: string;
  linked: boolean;
  insurableObjectNumber: number;
};

export type ObjectCrew = CommonObjectDetails & {
  crewDescription: string;
  crewRank: string;
  crewNationality: string;
};

export type ObjectFleet = CommonObjectDetails & {
  fleetDescription: string;
  numberOfVessels: number | null;
  majorityVesselType: string;
  totalGrossTonnage: number | null;
};

export type ObjectFleetDisplay = Modify<
  ObjectFleet,
  {
    numberOfVessels: string;
    totalGrossTonnage: string;
  }
>;

export type ObjectVessel = CommonObjectDetails & {
  vesselName: string;
  imoNumber: number | null;
  mmsiNumber: string | null;
  yearBuilt: number;
  grossTonnage: number;
  vesselTypeCode: string;
  vesselType: string;
  classificationSociety1: string;
  classificationSociety2: string;
  flag: string;
  portOfRegistry: string;
  callSign: string;
};

export type ObjectVesselDisplay = Modify<
  ObjectVessel,
  {
    grossTonnage: string | null;
  }
>;

export type ObjectYacht = ObjectVessel;

export type ObjectYachtDisplay = ObjectVesselDisplay;

export const submissionSpecialStatuses = [
  { id: 7, name: 'Declined' },
  { id: 9, name: 'Not Taken Up' },
];

export type SubmissionStatusName = {
  id: string | number;
  name: string;
};

export type ObjectsResponse = {
  objects: SubmissionObjects;
  covers: Cover[] | [];
  attachments: Attachment[] | [];
};

export type SubmissionInfo = {
  submissionNumber: number;
  submissionVersionId: number;
};
