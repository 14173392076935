import { styled, GenericDialog, Typography, IconButton, Box } from 'components';

export const StyledGenericDialog = styled(GenericDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: '450px',
  },
  '& .MuiDialogTitle-root': {
    backgroundColor: theme.palette.primary.main,
    padding: '14px 20px 10px 20px',
    '& .MuiTypography-root': {
      fontSize: '1.3rem',
      color: 'white',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiPaper-root': {
      minWidth: '350px',
    },
    '& .MuiDialogTitle-root': {
      '& .MuiTypography-root': {
        fontSize: '1.1rem',
      },
    },
  },
}));

export const StyledCloseButton = styled(IconButton)(() => ({ position: 'absolute', right: '0.5rem', top: '0.4rem', color: 'white' }));

export const StyledDetailsContainer = styled(Box)<{ component?: string }>(() => ({
  display: 'flex',
  '& .MuiBox-root:nth-child(even)': {
    textAlign: 'right',
  },
}));

export const StyledDetailsGroup = styled(Box)<{ component?: string }>(() => ({
  flex: '1',
  '& .MuiTypography-root': {
    fontSize: '0.8rem',
  },
}));

export const StyledDescription = styled(Typography)<{ component?: string }>(() => ({
  marginTop: '0.8rem',
  paddingTop: '0.8rem',
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  fontSize: '0.9rem',
  minHeight: '8rem',
  whiteSpace: 'pre-line',
  lineHeight: '1.1',
}));

export const StyledComments = styled(Typography)<{ component?: string }>(() => ({
  marginTop: '0.8rem',
  paddingTop: '0.8rem',
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  fontSize: '0.8rem',
  minHeight: '4rem',
  whiteSpace: 'pre-line',
  lineHeight: '1.1',
}));

export const StyledCommentsTitle = styled(Typography)<{ component?: string }>(() => ({
  marginBottom: '0.2rem',
}));
