import React from 'react';
import { Link, Tooltip } from 'components';
import { TaskItem } from 'tasks/model';
import { taskTitleLength, taskTypes } from 'tasks/const';
import { Link as RouterLink } from 'react-router-dom';
import {
  StyledBox,
  StyledApplicationReference,
  StyledDate,
  StyledTaskTitle,
  StyledTaskIcon,
  StyledDescription,
  StyledLinkContainer,
  StyledCheckCircleOutlineIcon,
  StyledDoNotDisturbAltIcon,
  StyledAssignmentIcon,
} from './TaskCard.styles';

type TaskCardProps = {
  handleClose: () => void;
  handleOpenTaskModal: (task: TaskItem) => void;
  task: TaskItem;
};

const TaskCard: React.FC<TaskCardProps> = ({ handleClose, handleOpenTaskModal, task }) => {
  return (
    <StyledBox>
      <Tooltip title={task.title.length > taskTitleLength ? task.title : null}>
        <StyledTaskTitle color="primary" onClick={() => handleOpenTaskModal(task)} variant="h6">
          {task.titleToDisplay}
        </StyledTaskTitle>
      </Tooltip>
      <StyledApplicationReference>
        {task.application.title} - {task.taskType.type}
      </StyledApplicationReference>
      <StyledTaskIcon>
        {task.taskType.taskTypeId === taskTypes.approval && <StyledCheckCircleOutlineIcon />}
        {task.taskType.taskTypeId === taskTypes.declinature && <StyledDoNotDisturbAltIcon />}
        {task.taskType.taskTypeId === taskTypes.assignment && <StyledAssignmentIcon />}
      </StyledTaskIcon>
      <StyledDescription variant="caption">{task.comments ?? task.description.split('\n')[0]}</StyledDescription>
      <StyledLinkContainer variant="subtitle2">
        <Link component={RouterLink} onClick={handleClose} target="_blank" to={task.viewUrl}>
          Link
        </Link>
      </StyledLinkContainer>
      {task.dueDateUI && <StyledDate>Due By: {task.dueDateUI}</StyledDate>}
    </StyledBox>
  );
};

export default TaskCard;
