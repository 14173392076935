import { Box, styled, Typography } from 'components';
import { AssignmentIcon, CheckCircleOutlineIcon, DoNotDisturbAltIcon } from 'icons';

export const StyledBox = styled(Box)(() => ({
  position: 'relative',
  padding: '0.7rem 1rem 0.5rem 1rem',
  marginBottom: '1rem',
  boxShadow: '2px 2px 7px rgba(0, 0, 0, 0.25)',
  '& .MuiTypography-root': {
    display: 'block',
  },
}));

export const StyledTaskIcon = styled('div')(() => ({
  position: 'absolute',
  top: '0.6rem',
  right: '0.7rem',
}));

export const StyledTaskTitle = styled(Typography)<{ component?: string }>(() => ({
  display: 'block',
  marginBottom: '0.3rem',
  cursor: 'pointer',
  fontSize: '0.95rem',
  width: '90%',
}));

export const StyledDescription = styled(Typography)<{ component?: string }>(() => ({
  display: 'block',
  marginBottom: '0.5rem',
}));

export const StyledCheckCircleOutlineIcon = styled(CheckCircleOutlineIcon)<{ component?: string }>(({ theme }) => ({
  color: theme.palette.success.light,
}));

export const StyledDoNotDisturbAltIcon = styled(DoNotDisturbAltIcon)<{ component?: string }>(({ theme }) => ({
  color: theme.palette.error.light,
}));

export const StyledAssignmentIcon = styled(AssignmentIcon)<{ component?: string }>(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const StyledApplicationReference = styled(Typography)<{ component?: string }>(({ theme }) => ({
  display: 'inline-block !important',
  width: 'auto',
  fontSize: '0.7rem',
  backgroundColor: `${theme.palette.text.secondary}`,
  color: 'white',
  padding: '2px 7px 1px 7px',
  borderRadius: '10px',
  marginBottom: '1rem',
}));

export const StyledLinkContainer = styled(Typography)<{ component?: string }>(() => ({
  position: 'absolute',
  top: '2.4rem',
  right: '0.8rem',
  fontSize: '0.9rem',
}));

export const StyledDate = styled(Typography)<{ component?: string }>(() => ({
  fontSize: '0.7rem',
}));
