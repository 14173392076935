import axios, { Canceler } from 'axios';
import { AsyncStatus } from 'common';
import { httpStatusCode } from 'common/const';
import { useSnackbar } from 'components';
import { useCallback, useState } from 'react';
import TaskPreferencesService from 'tasks/services/task-preferences-service';
import { TaskPreference } from '../../model';

const usePreferences = (userIdParam: number) => {
  const { enqueueSnackbar } = useSnackbar();
  const [result, setResult] = useState<TaskPreference>();
  const [status, setStatus] = useState<AsyncStatus>('idle');
  const [newPreference] = useState<TaskPreference>({ preferenceId: 0, userId: userIdParam, email: true, teams: true });

  const fetchPreferences = useCallback((): Canceler => {
    setStatus('fetching');
    const [getPreference, cancel] = TaskPreferencesService.getPreferences(userIdParam);

    getPreference
      .then(response => {
        setResult(response);
        setStatus('resolved');
      })
      .catch(e => {
        if (e.StatusCode === httpStatusCode.notFound) {
          const [createPreference] = TaskPreferencesService.addPreferences(newPreference);
          createPreference.then(createResponse => {
            fetchPreferences();
          });
        } else if (!axios.isCancel(e)) {
          enqueueSnackbar(e.Message || 'Error occurred while retrieving Task Preference.', 'error');
          setStatus('rejected');
        }
      });

    return cancel;
  }, [enqueueSnackbar]);

  return {
    preferences: result,
    fetchPreferences,
    isLoading: status === 'idle' || status === 'fetching',
  };
};

export default usePreferences;
