import React from 'react';
import { TaskItem } from 'tasks/model';
import { Link, Typography } from 'components';
import { Link as RouterLink } from 'react-router-dom';
import { CloseIcon } from 'icons';
import {
  StyledGenericDialog,
  StyledDescription,
  StyledCloseButton,
  StyledDetailsContainer,
  StyledDetailsGroup,
  StyledComments,
  StyledCommentsTitle,
} from './TaskModal.styles';

type TaskModalProps = {
  handleCloseTaskModal: () => void;
  openTaskModal: boolean;
  task: TaskItem;
};

const TaskModal: React.FC<TaskModalProps> = ({ handleCloseTaskModal, task, openTaskModal }) => {
  return (
    <StyledGenericDialog onClose={() => {}} open={openTaskModal} title={task.title}>
      <StyledCloseButton aria-label="close" onClick={handleCloseTaskModal}>
        <CloseIcon />
      </StyledCloseButton>

      <StyledDetailsContainer>
        <StyledDetailsGroup>
          <Typography>
            <b>Status:</b> {task?.taskItemStatus.statusTitle}
          </Typography>
          <Typography>
            <b>Application:</b> {task.application.title}
          </Typography>
          <Typography>
            <b>Link: </b>
            <Link component={RouterLink} onClick={handleCloseTaskModal} target="_blank" to={`../${task.viewUrl}`}>
              Link
            </Link>
          </Typography>
        </StyledDetailsGroup>
        <StyledDetailsGroup>
          <Typography>
            <b>Assigned By: </b>
            <Link href={`mailto:${task?.assignedByUser.email}?subject=Re:%20${encodeURIComponent(task?.title)}`}>
              {task?.assignedByUser.name}
            </Link>
          </Typography>
          <Typography>
            <b>Assigned Date:</b> {task.taskCreatedUI}
          </Typography>
          {task.dueDateUI && (
            <Typography>
              <b>Due By:</b> {task.dueDateUI}
            </Typography>
          )}
        </StyledDetailsGroup>
      </StyledDetailsContainer>
      <StyledDescription>{task.description}</StyledDescription>
      {task.comments && (
        <StyledComments>
          <StyledCommentsTitle>Additional Comments:</StyledCommentsTitle>
          {task.comments}
        </StyledComments>
      )}
    </StyledGenericDialog>
  );
};

export default TaskModal;
