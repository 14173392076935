import { AuthorisorTypeName, AuthorisorTypeValue, ColumnIcon, MenuOptionName, MenuOptionValue, Pagination } from 'common/model';
import { Theme } from '@mui/material/styles';

export const colors = {
  red: '#BB0000',
  amber: '#BB9900',
  green: '#009900',
  violet: '#450E84',
  errorMessage: '#f44336',
};

export const colorDisabled = (theme?: Theme) => {
  return theme?.palette.text.disabled;
};

export const screeningAuthorisorType = 'SS';

export const authorisorTypes: Record<AuthorisorTypeName, AuthorisorTypeValue> = {
  Screening: 'SS',
  ApproveBroker: 'AO',
};

export const menuOptions: Record<MenuOptionName, MenuOptionValue> = {
  SubmissionManagement: 'SUBMAN',
  SubmissionReadOnly: 'SUBRO',
  BrokerManagement: 'BROKMAN',
  BrokerReadOnly: 'BROKRO',
  BrokerGroupManagement: 'BROKGRPMAN',
  BrokerGroupReadOnly: 'BROKGRPRO',
  MemberReports: 'MEMREPS',
  StatementReadOnly: 'STATRO',
  MemberGroupManagement: 'MEMBGRPMAN',
  MemberGroupReadOnly: 'MEMBGRPRO',
  ObjectMaintenanceManagement: 'OMMAN',
  ObjectMaintenanceReadOnly: 'OMRO',
  VesselSurveyManagement: 'VSURVEYMAN',
  VesselSurveyReadOnly: 'VSURVEYRO',
};

export const eTagHttpHeader = {
  requestHeaderName: 'If-Match',
  responseHeaderName: 'etag',
};

export const screeningAuthorisorTooltipMessage = 'You do not have enough permissions to update Screening result.';
export const CONFIRM_LEAVE_WITHOUT_SAVING_MESSAGE = 'Are you sure you want to leave without saving changes?';
export const BLOCKED_EDIT_ADD_MESSAGE = 'Changes need to be saved before using this option.';

export const DEFAULT_PAGINATION: Pagination = {
  pageNumber: 0,
  pageSize: 30,
};

export const START_DATE = new Date(101, 0, 1);
export const END_DATE = new Date(3000, 11, 31);

export const CHECK_COLUMN_ICON: ColumnIcon = {
  iconName: 'check',
  iconColor: colors.green,
};

export const CANCEL_COLUMN_ICON: ColumnIcon = {
  iconName: 'close',
  iconColor: colors.red,
};

export const YACHT_CODE = 'YACH';
export const DELETED_VALUE_MESSAGE = 'UNKNOWN';
export const CONTENT_LOCATION_HEADER = 'Content-Location';

export const OBO_TYPE_GROUP_CODE = 'OBOS';
export const TANKER_TYPE_GROUP_CODE = 'TNKR';

export const POLICY_GROUP_P_AND_I_CLASS_1_CODE = '01';

export const httpStatusCode = {
  created: 201,
  forbidden: 403,
  notFound: 404,
  conflict: 409,
  unavailableForLegalReasons: 451,
};

export const workbenchHttpHeader = {
  warningMessage: 'x-warning-message',
};

export const YOU_DO_NOT_HAVE_PERMISSIONS_TO_ACCESS_THIS_PAGE = 'You do not have permissions to access this page';

export const OPERATION_PERMITTED_FOR_SUBMAN_ONLY = "Operation is permitted for users with 'Submission Management' permission only.";

export const OPERATION_PERMITTED_FOR_BROKMAN_ONLY = "Operation is permitted for users with 'Broker Management' permission only.";

export const OPERATION_PERMITTED_FOR_OMMAN_ONLY = "Operation is permitted for users with 'Object Maintenance Management' permission only.";

export const OPERATION_PERMITTED_FOR_VSURVEYMAN_ONLY = "Operation is permitted for users with 'Vessel Survey Management' permission only.";

export const OPERATION_PERMITTED_FOR_MEMBGRPMAN_ONLY = "Operation is permitted for users with 'Member Group Management' permission only.";

export const OPERATION_PERMITED_FOR_LPD_HANDLERS_ONLY = 'Operation is permitted for LPD Handlers only.';

export const MIN_RECEIVED_DATE_FILTER_VALUE = '1900-01-01';
