import { DetailsLabel } from '../model';

export const submissionsSummaryLabels: DetailsLabel = [
  { value: 'submissionNumber', label: 'Submission Number', dataTestId: 'submission-number' },
  { value: 'description', label: 'Description', detailLabelLgValue: 8, dataTestId: 'description', labelGridSizeMd: 2 },
  { value: 'versionInfo', label: 'Version', dataTestId: 'version-info' },
  {
    value: 'directBusinessIndicator',
    label: 'Direct',
    type: 'checkbox',
    infoTooltip: 'Indicates if Business is Direct or via a Broker',
    dataTestId: 'direct-business-indicator',
  },
  {
    value: 'indicationIndicator',
    label: 'Indication',
    type: 'checkbox',
    infoTooltip: 'Identifies if the Submission is for an Indication or Quote',
    dataTestId: 'indication-indicator',
  },
  { value: 'syndicate', label: 'Syndicate', dataTestId: 'syndicate' },
  { value: 'underwriter', label: 'Underwriter', availableLabelName: 'underwriterAvailable', dataTestId: 'underwriter' },
  {
    value: 'underwritingExecutive',
    label: 'Underwriting Executive',
    availableLabelName: 'underwritingExecutiveAvailable',
    dataTestId: 'underwriting-executive',
  },
  { value: 'assigneeName', label: 'Assignee', dataTestId: 'assignee-name' },
];

export const memberDetailsLabels: DetailsLabel = [
  { value: 'memberGroupCode', label: 'Member Group Code', dataTestId: 'member-group-code' },
  { value: 'memberGroupAddress', label: 'Member Group Full Address', dataTestId: 'member-group-address' },
  { value: 'memberGroup', label: 'Member Group Name', dataTestId: 'member-business' },
  { value: 'memberGroupCountry', label: 'Member Group Country', dataTestId: 'member-group-country' },
];

export const placingBrokerDetailsLabels: DetailsLabel = [
  {
    value: 'placingBrokerName',
    label: 'Broker Name',
    availableLabelName: 'placingBrokerAvailable',
    dataTestId: 'placing-broker-name',
  },
  { value: 'placingBrokerAddress', label: 'Broker Address', dataTestId: 'placing-broker-address' },
  { value: 'placingBrokerCountry', label: 'Broker Country', dataTestId: 'placing-broker-country' },
];

export const producingBrokerDetailsLabels: DetailsLabel = [
  {
    value: 'producingBrokerName',
    label: 'Broker Name',
    availableLabelName: 'producingBrokerAvailable',
    dataTestId: 'producing-broker-name',
  },
  { value: 'producingBrokerAddress', label: 'Broker Address', dataTestId: 'producing-broker-address' },
  { value: 'producingBrokerCountry', label: 'Broker Country', dataTestId: 'producing-broker-country' },
];

export const statusDetailsLabels: DetailsLabel = [
  { value: 'status', label: 'Submission Status', dataTestId: 'status' },
  {
    value: 'pendingIndicator',
    label: 'Pending',
    type: 'checkbox',
    infoTooltip: 'Indicates the Submission requires no immediate action as outcome of enquiry expected to remain unconfirmed',
    dataTestId: 'pending-indicator',
  },
  { value: 'enquiryReceived', label: 'Enquiry Received', type: 'date', dataTestId: 'enquiry-received' },
  {
    value: 'ntuOtherClubName',
    label: 'NTU Other Insurer',
    availableLabelName: 'ntuOtherClubNameAvailable',
    dataTestId: 'ntu-other-club-name',
  },
  { value: 'lastIndicationIssued', label: 'Last Indicated', type: 'date', dataTestId: 'last-indication-issued' },
  {
    value: 'ntuDeclinedReason',
    label: 'NTU/Declined Reason',
    availableLabelName: 'ntuDeclinedReasonAvailable',
    dataTestId: 'ntu-declined-reason',
  },
  { value: 'lastQuoteIssued', label: 'Last Quoted', type: 'date', dataTestId: 'last-quote-issued' },
  { value: 'noOrderDetails', label: 'No Order Details', dataTestId: 'no-order-reason' },
];

export const screeningSummary: DetailsLabel = [
  {
    value: 'vesselBackground',
    label: 'Vessel Background',
    availableLabelName: 'vesselBackgroundAvailable',
    dataTestId: 'vessel-background',
  },
  {
    value: 'vesselDueDiligence',
    label: 'Vessel Due Diligence',
    availableLabelName: 'vesselDueDiligenceAvailable',
    dataTestId: 'vessel-due-diligence',
  },
  {
    value: 'assuredDueDiligence',
    label: 'Assured Due Diligence',
    availableLabelName: 'assuredDueDiligenceAvailable',
    dataTestId: 'assured-due-diligence',
  },
];

export const yachtMemberDetails: DetailsLabel = [
  { value: 'memberGroupCode', label: 'Member Group Code', dataTestId: 'member-group-code' },
  { value: 'memberGroupAddress', label: 'Member Group Full Address', dataTestId: 'member-group-address' },
  { value: 'memberGroup', label: 'Member Group Short Name', dataTestId: 'member-group' },
  { value: 'memberGroupCountry', label: 'Member Group Country', dataTestId: 'member-group-country' },
  {
    value: 'yachtBusinessIndicator',
    label: 'Yacht Member',
    type: 'checkbox',
    infoTooltip: 'Indicates if business is related to Yachts only',
    dataTestId: 'yacht-business-indicator',
  },
  {
    value: 'yachtBusinessName',
    label: 'Yacht Member Name',
    availableLabelName: 'yachtBusinessAvailable',
    visibleWhenTrue: 'yachtBusinessIndicator',
    dataTestId: 'yacht-business-name',
  },
  {
    value: 'yachtBusiness',
    label: 'Yacht Member Business Name Code',
    visibleWhenTrue: 'yachtBusinessIndicator',
    dataTestId: 'yacht-business',
  },
  {
    value: 'yachtBusinessAddress',
    label: 'Yacht Member Full Address',
    visibleWhenTrue: 'yachtBusinessIndicator',
    dataTestId: 'yacht-business-address',
  },
  {
    value: 'yachtBusinessCountry',
    label: 'Yacht Member Country',
    visibleWhenTrue: 'yachtBusinessIndicator',
    dataTestId: 'yacht-business-country',
  },
];

export const statusNames = [
  { id: 'Y', name: 'Yes' },
  { id: 'N', name: 'No' },
  { id: 'X', name: 'Not Applicable' },
];

export const objectTypes = [
  {
    objectType: 'Crew',
    objectTypeCode: 'CW',
  },
  {
    objectType: 'Fleet',
    objectTypeCode: 'FT',
  },
  {
    objectType: 'Vessel',
    objectTypeCode: 'VS',
  },
];

export const VALUE_CANNOT_BE_CHANGED_IN_CURRENT_STATUS = 'This value cannot be changed in current Submission status.';

export const CHANGING_MEMBER_NOT_POSSIBLE_BECAUSE_OF_EXISTING_OBJECTS = 'Changing Member is not possible because of existing Objects.';

export const CHANGING_MEMBER_NOT_POSSIBLE_BECAUSE_OF_EXISTING_DOCUMENTS =
  'Changing Member is not possible because of existing Document(s).';

export const CHANGE_STATUS_DIALOG_TITLE = 'Change Submission Status';

export const CHANGE_STATUS_DIALOG_MESSAGE = "Are you sure you want to move Submission to status '{0}'?";
