import { useState, useEffect } from 'react';

import { SimslRoute, ModuleRoutingInfo } from 'common/model';
import { sliceStart } from 'utilities/misc/StringHelper';
import { RoutePath } from './RoutePath';

type ActiveModule = {
  name: string;
  mainPath: string;
  activeRoute: SimslRoute | null;
};

const useModuleInfo = (modules: ModuleRoutingInfo[], pathname: string) => {
  const [module, setModule] = useState<ActiveModule | null>(null);

  useEffect(() => {
    if (pathname === RoutePath.Home) {
      setModule(null);
      return;
    }

    const activeModule = modules.find(x => pathname.startsWith(x.mainPath)) || null;
    let activeRoute = null;
    if (activeModule) {
      if (pathname === activeModule?.mainPath) {
        activeRoute = activeModule?.routes?.find(x => x.path === RoutePath.MainPath) || null;
      } else {
        const subPath = sliceStart(pathname, activeModule?.mainPath);
        // reverse() is needed below to provide support for scenarios when the first route URL is a prefix of the second/next route URL
        activeRoute =
          activeModule?.routes
            ?.slice()
            .reverse()
            ?.find(x => {
              return sliceStart(subPath, RoutePath.Home).startsWith(x.path) || x.path === RoutePath.MainPath;
            }) || null;
      }
    }

    setModule({
      name: activeModule?.name || '',
      mainPath: activeModule?.mainPath || '',
      activeRoute,
    });
  }, [modules, pathname]);

  return { module };
};

export default useModuleInfo;
