import React, { useEffect, useState, useCallback } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Typography } from 'components';
import { SimslRoute } from 'common';
import { textSpacesToDashFormat } from 'utilities/misc/StringHelper';
import { StyledAppBar, StyledTab, StyledTabs } from './TabsPageNavigation.styles';

type TabsPageNavigationProps = {
  routes: SimslRoute[];
  disabled?: boolean;
};

const TabsPageNavigation: React.FC<TabsPageNavigationProps> = ({ disabled = false, routes = [] }) => {
  const { pathname } = useLocation();

  const getSelectedPath = useCallback(() => {
    // reverse() is needed below to provide support for scenarios when the first tab URL is a prefix of the second/next tab URL
    const selectedRoute = routes
      .slice()
      .reverse()
      .find(x => pathname.includes(x.path));
    return selectedRoute?.path || '';
  }, [routes, pathname]);

  const [selectedPath, setSelectedPath] = useState(() => getSelectedPath());

  useEffect(() => {
    setSelectedPath(getSelectedPath());
  }, [getSelectedPath]);

  return (
    <StyledAppBar color="transparent" elevation={0} position="static">
      <StyledTabs indicatorColor="primary" textColor="primary" value={selectedPath}>
        {routes.map(route => (
          <StyledTab
            key={route.path}
            component={RouterLink}
            data-testid={`${textSpacesToDashFormat(route.name)}-tab`}
            disabled={disabled}
            isSelected={!!selectedPath}
            label={<Typography variant="h6">{route.name}</Typography>}
            to={route.path}
            value={route.path}
          />
        ))}
      </StyledTabs>
    </StyledAppBar>
  );
};

export default TabsPageNavigation;
