import axios, { Canceler } from 'axios';
import { AsyncStatus } from 'common';
import { useSnackbar } from 'components';
import { useCallback, useState } from 'react';
import { taskTitleLength } from 'tasks/const';
import TasksService from 'tasks/services/tasks-service';
import { TaskItem, TaskFilters, TaskSearchRequest, TaskSearchResponse } from '../../model';

const transformResponse = (data: TaskItem[]) => {
  const result = data.map(x => ({
    ...x,
    id: x.taskItemId,
    titleToDisplay: x.title.length > taskTitleLength ? `${x.title.substring(0, taskTitleLength)}...` : x.title,
  }));

  return result;
};

const useTasks = (filterParam: TaskFilters) => {
  const { enqueueSnackbar } = useSnackbar();
  const [searchResults, setSearchResults] = useState<TaskSearchResponse>();
  const [status, setStatus] = useState<AsyncStatus>('idle');
  const [filters] = useState<TaskFilters>(filterParam);

  const fetchTasks = useCallback((): Canceler => {
    setStatus('fetching');

    const requestFilters = {
      ...filters,
    } as TaskFilters;

    const requestData: TaskSearchRequest = {
      pageSize: 200,
      pageNumber: 1,
      filters: requestFilters,
    };

    const [request, cancel] = TasksService.getTasks({ ...requestData });

    request
      .then(response => {
        const Tasks = transformResponse(response.items);
        setSearchResults({ ...response, items: Tasks });
        setStatus('resolved');
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          enqueueSnackbar(e.Message || 'Error occurred while retrieving Task list.', 'error');
          setStatus('rejected');
        }
      });

    return cancel;
  }, [enqueueSnackbar, filters]);

  return {
    tasks: searchResults?.items || [],
    fetchTasks,
    totalResults: searchResults?.totalSearchResultsCount || 0,
    isLoading: status === 'idle' || status === 'fetching',
  };
};

export default useTasks;
